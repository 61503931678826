import React, { FormEventHandler, MouseEventHandler } from 'react';
import { IonAlert, IonButton, IonButtons, IonCard, IonCardContent, IonCardSubtitle, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonProgressBar, IonSegment, IonSegmentButton, IonSlide, IonSlides, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { bulb, flash, qrCodeOutline } from 'ionicons/icons';
import './About.css';
import {db, realDb} from '../utils/firebase-config';
import {addDoc, collection, doc, getDocs, updateDoc} from 'firebase/firestore';
import { ref, onValue, set, update} from "firebase/database";



import axios from 'axios';
// // import { Chart, Line } from 'react-chartjs-2'
import { Line } from 'react-chartjs-2'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
// import { profile } from 'console';
import QrReader from 'react-qr-reader';
import { getDatabase } from 'firebase/database';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)





interface SayHelloProps {
  accessToken?: string;
}
interface SayHelloState {
  user: any;
  myPower:any;
  myStatusValue: string;
  UnitPower:string;
  notShowSearch?: string;
  segment?: string;
  devices: any;
  myLocation: object,
  theirLocation: object,
  firstModalRef?: any;
  showTracker: boolean;
  extraParams: string;
  showModal:boolean;
  scanResultFile?: any;
  qrRef: React.RefObject<QrReader>;
  myForm:any;
  showLoadingState:any;
  sendTwilio:FormEventHandler;
  setSegment: Function;
  onScanFile?: MouseEventHandler;
  handleScanFile: any;
  showAlertState:any;
  handleErrorFile?: any;
  handleClick: Function;
  promptConfig: Function;
  setShowLoading: Function;
  setShowAlert: Function;
  setMyStatus: Function;
  setMyPower:Function;
  setMyUser:Function;
}

class Home extends React.Component<SayHelloProps,SayHelloState> {
  
  constructor(props: SayHelloProps) {
    super(props);

    this.state = {
      user: {},
      myPower: "___",
      myStatusValue: "fetching status...",
      UnitPower: "... ",
      scanResultFile:"",
      showModal:false,
      showTracker: false,
      notShowSearch: "none",
      segment: "Dashboard",
      devices: [],
      extraParams: "",
      showAlertState: {header: "", subHeader: "", message: "", buttons: [], showAlert: false},
      showLoadingState:false,
      myLocation: {},
      theirLocation: {},
      firstModalRef: React.createRef(),
      qrRef: React.createRef(),
      myForm: React.createRef(),
      setSegment: (_txt: string)=>{
        this.setState({...this.state, segment: _txt}); 
      },
      setShowAlert:(_txt:any)=>{
        this.setState({...this.state, showAlertState: _txt}); 

      },
      setMyUser:(_txt:any)=>{
        console.log(_txt)
        this.setState({...this.state, user: _txt}); 

      }
      
      ,
      sendTwilio: (e:any) =>{
        e.preventDefault();
        if(this.state.myForm != null){
          if(this.state.myForm.current != null){
            var formData = new FormData(this.state.myForm.current);
            var currentFormObj = Object.fromEntries(formData.entries());
            axios.post("api/backend", currentFormObj, {headers: {'Content-Type': 'application/json'}}).then((res:any)=>{
              return res.data;
            }).then((response: any)=>{
              console.log(response);
  
            }).catch((err:any)=>{
              console.log(err)
              // console.log(err.message)
            })
            
          }
        }
      },
      onScanFile: ()=>{
        if (this.state.qrRef != null){ 
          if (this.state.qrRef.current != null){
            this.state.qrRef.current.openImageDialog();        //  openImageDialog();
          }
        }
      },
      handleClick: (_promtState: boolean)=> {
        this.setState({...this.state, showModal: _promtState});
      },
      setShowLoading: (_loadState: boolean)=>{
        this.setState({...this.state, showLoadingState: _loadState}); 

      },
      handleScanFile: (result:any)=>{
        if (result) {
          this.state.setShowLoading(true);

          const collectionRef = doc(db, "users", this.state.user.docID);

          updateDoc(collectionRef, { "": result}).then(res=>{
            console.log(res)
            setTimeout(() => {
              this.state.setShowLoading(false);
              this.state.promptConfig(false);
              this.setState({...this.state, user: {...this.state.user, currentMeter: result}});
            }, 1000);

          });
        }
      },
      handleErrorFile: (error:any)=>{
          console.log(error);         
      },
      promptConfig: (_promtState: boolean)=>{
        this.state.handleClick(_promtState);
      },
      setMyStatus: (_status: string, _power:string)=>{
        this.setState({...this.state, myStatusValue: _status}); 
      },
      setMyPower: ( _power:string)=>{
        this.setState({...this.state, UnitPower: _power}); 
      }
    }
  }

  componentDidMount() {    
    var url = "https://mp24.appimate.com//testing.php?status=true";
    var urlPower = "https://mp24.appimate.com//testing.php?";
    this.fetchFunction(url, this.state.setMyStatus);
    this.fetchFunction(urlPower, this.state.setMyPower); 
    
    this.getUsers().then((res: any)=>{
      console.log(res);
      
      console.log(realDb);
      const starCountRef = ref(realDb, 'powerMeter/');
      console.log("Also here!");
      console.log(starCountRef);
      onValue(starCountRef, (snapshot:any) => {
        console.log(snapshot.val())
        console.log("Also here!!!!!!!!!!!!!!!!!!!!!");
        const data = snapshot.val();
        console.log(data);

        console.log(data.currentPower);
        const power = data.currentPower;
        const deviceMeterNumber = data.meterNumber;
        console.log(deviceMeterNumber, this.state.user.currentMeter);
        console.log(power);
        if (deviceMeterNumber === this.state.user.currentMeter) {
          console.log(power)
          this.setState({...this.state, myPower:power});
        };
          console.log(data);
          console.log(this.state.user.currentMeter)
      });
    }).catch(err=>{
      console.log(err)
    });
    
  }

  getUsers = async ()=> {
    var username = "0832548449";
    const collectionRef = collection(db, "users");
    const data = await getDocs(collectionRef)
    // console.log(data.docs);
    // // this.state.setMyUser(data.docs.map((doc:any) => ({ ...doc.data(), id: doc.id})))

    data.docs.forEach((doc:any)=>{
      console.log(doc.data().phoneNumber, username);
      if (doc.data().phoneNumber === username) {
        console.log("Here!")
        // myUser.docID = doc.id;
        // myUser.data = doc.data();
        this.state.setMyUser({...doc.data(), docID: doc.id})
      };
    });
  }


  fetchFunction = async (url: string, callbackFunction: Function) => {
    var headers = {
      Authorization: "Bearer "+this.props.accessToken
    };
    axios.get(url, { headers }).then((response)=>{
      return response.data;
    }).then(result=>{
      console.log(result);
      if (result.success) {
        callbackFunction(result.msg);
        setTimeout(() => {
          callbackFunction(result.msg2);
        }, 1500);
      } else {
        callbackFunction(result.msg); 
      }
    }).catch(error=>{
      console.log(error.msg);
      callbackFunction("Status: (not found)");
    });
  }
   mystate = {
    labels: ['Mon', 'Tue', 'Wed',
             'Thur', 'Fri', 'Sat', 'Sun'],
    datasets: [
      {
        label: 'Power Consumption',
        fill: false,
        lineTension: 0.5,
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: [65, 59, 80, 81, 56,69, 70]
      }
    ]
  }

  actOnMyMeter = (theUser: any) => {
    var buttons:any[] = [
      {
        text: 'Back',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Confirm Cancel');
        }
      },
      {
        text: 'Unpair',
        handler: () => {
          this.state.setShowLoading(true);
          const collectionRef = doc(db, "users", this.state.user.docID);
          updateDoc(collectionRef, { "currentMeter": ""}).then(res=>{
            // console.log(res)
            setTimeout(() => {
              this.state.setShowLoading(false);
              this.state.promptConfig(false);
              this.setState({...this.state, user: {...this.state.user, currentMeter: ""}});
              this.state.setShowAlert({header: "Meter: "+theUser.currentMeter, subHeader: "DOne", message: "Upaired successfully.", buttons: [{text: "Done", role: 'cancel',}], showAlert: true});
            }, 1000);
          })
        }
      }
    ];
    this.state.setShowAlert({header: "Meter: "+theUser.currentMeter, subHeader: "Actions available on this meter.", message: "What do you want to do?", buttons: buttons, showAlert: true});

  }
  


  render() {

    const {
      myStatusValue,
      UnitPower,
      segment,
      scanResultFile,
      // myForm,
      qrRef,
      setSegment,
      firstModalRef,
      // sendTwilio,
      // devices,
      // onScanFile,
      showModal,
      handleScanFile,
      handleErrorFile,
      promptConfig,
      showLoadingState,
      setShowLoading,
      setShowAlert,
      showAlertState,
      user,
      myPower

    } = this.state;
    var myStatusIcon:any;
    var myStatusColor:any;
    var buttonColor:any = "danger";
    if (myStatusValue === 'Status: active') {
      myStatusIcon = 'checkmark';
      // myStatusColor = 'success';
      myStatusColor = 'colorGreen';
      buttonColor = 'success';
    }else{
      myStatusIcon = 'alert';
      // myStatusColor = 'danger';
      myStatusColor = 'colorRed';
      buttonColor = 'danger';
    }
    console.log(myStatusValue, myStatusColor);
    const slideOpts = {initialSlide: 0, speed: 400, slidesPerView: 1, autoplay: {delay: 2500}};

    // console.log("UnitPower", UnitPower);
    // console.log("user", user);

    console.log(user);
    console.log(myPower);

    return (
      
      <>
        <IonPage ref={firstModalRef}>
          {/* <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonBackButton defaultHref="home" text="Back" icon={chevronBack} />
              </IonButtons>
              <IonTitle>Dashboard</IonTitle>
            </IonToolbar>
          </IonHeader> */}
          <IonContent fullscreen>
            {/* <IonHeader collapse="condense">
              <IonToolbar>
                <IonTitle size="large">Orders</IonTitle>
              </IonToolbar>
            </IonHeader> */}
            <br/>
            <br/>
            <IonCard color="light" className="myCard">
              <div className="img-wrapper">
                  <img alt="" src="./assets/images/epec1.jpg" className="myAvatar"/>
                  <IonButton color={buttonColor} className="myButton">
                    <IonIcon slot="icon-only" icon={myStatusIcon} className={"myIcon "+myStatusColor}/>
                  </IonButton>
              </div>
              <IonCardContent class="ion-text-center">
                  <h2> { user.firstname + ' '+ user.lastname }</h2>
                <br />
                  <div className='progressBar'>
                    <IonProgressBar color="success" value={0.8}></IonProgressBar><br />
                  </div>
                  <IonText color="medium">
                  <div className="ion-justify-content-center hello">
                      <IonIcon icon={bulb} color="green"/>  <IonLabel>{myStatusValue}   </IonLabel>           
                      
                      <IonIcon icon={flash} color="medium"/><IonLabel>{myPower} kWh</IonLabel>
                  </div>
                  </IonText>
              </IonCardContent>
            </IonCard>
            <IonSegment value={segment} onIonChange={e => setSegment(e.detail.value!)}>
              <IonSegmentButton value="Dashboard">
                <IonLabel>Dashboard </IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="Configuration">
                <IonLabel>Configuration</IonLabel>
              </IonSegmentButton>
            </IonSegment>
            
            <div >
              
              {
                (segment==='Dashboard') ? (
                  <><div className="myGraph">
                    <p>Weekly power consumption</p>
                    <Line
                      data={this.mystate} />
                  </div>
                  <IonSlides options={slideOpts}>
                    <IonSlide className="myColor">
                    <IonCard className="topMarketsSliderCard">
                        <div className="imagePart">
                          <img alt="" src="assets/images/B.jpeg" />
                        </div>
                        <IonCardContent className="descriptionPart">
                          <IonCardSubtitle className="cardTitle">Get Advertising here</IonCardSubtitle>
                        </IonCardContent>
                      </IonCard>
                    </IonSlide>
                    <IonSlide>
                    <IonCard className="topMarketsSliderCard">
                        <div className="imagePart">
                          <img alt="" src="assets/images/B2.jpeg"  />
                        </div>
                        <IonCardContent className="descriptionPart">
                          <IonCardSubtitle className="cardTitle">Get Advertising here</IonCardSubtitle>
                        </IonCardContent>
                      </IonCard>
                    </IonSlide>
                    <IonSlide>
                      <IonCard className="topMarketsSliderCard">
                        <div className="imagePart">
                          <img alt="" src="assets/images/B3.jpeg"  />
                        </div>
                        <IonCardContent className="descriptionPart">
                          <IonCardSubtitle className="cardTitle">Get Advertising here</IonCardSubtitle>
                        </IonCardContent>
                      </IonCard>
                    </IonSlide>

                  </IonSlides>
                  </>                  
                ) : (
                  <>
                    <IonList>
                      {
                      ((user.currentMeter !== "")&&(user.currentMeter !== undefined)&&(user.currentMeter !== null))?(
                        <IonItem key={user.currentMeter} lines="full" onClick={()=>{
                          this.actOnMyMeter(user)
                        }} >
                            <IonLabel>Meter No : {user.currentMeter}</IonLabel>
                            {/* <IonLabel slot="end">{voucher.amount}</IonLabel> */}
                        </IonItem>
                      ):("") 
                      }
                      {/* {
                        (devices.map((device: any)=>{
                          return <IonItem key={device.id}>Unique device: {device.code}</IonItem>
                        }))
                      } */}
                    </IonList>
                    <IonButton expand="block" onClick={()=>{promptConfig(true)}}><IonIcon icon={qrCodeOutline}></IonIcon>Scan to Configure</IonButton>
                    
                    <IonLoading
                      isOpen={showLoadingState}
                      onDidDismiss={() => setShowLoading(false)}
                      message="Loading..."
                    />
                    <IonAlert
                      isOpen={showAlertState.showAlert}
                      onDidDismiss={() => setShowAlert({...showAlertState, showAlert: false})}
                      header={showAlertState.header}
                      subHeader={showAlertState.subHeader}
                      message={showAlertState.message}
                      buttons={showAlertState.buttons}
                    />
                    <IonModal isOpen={showModal} onDidDismiss={()=>{promptConfig(false)}} cssClass='my-custom-class1' swipeToClose={true}>
                      <IonHeader>
                        <IonToolbar>
                          <IonButtons slot="start">
                            {/* <IonBackButton defaultHref="home" text="Back" icon={chevronBack} /> */}
                          </IonButtons>
                          <IonTitle>Scan to configure</IonTitle>
                        </IonToolbar>
                      </IonHeader>
                      <div>
                        <QrReader 
                          ref={qrRef}
                          delay={200}
                          style={{ width: '100%' }}
                          onError={handleErrorFile}
                          onScan={handleScanFile}
                          // legacyMode
                          />  
                        <p>{scanResultFile}</p>
                      </div>
                      <IonButton onClick={()=>{; promptConfig(false); }}>Close</IonButton>
                    </IonModal>
                  </>
                )
              }
            </div>
          </IonContent>
        </IonPage>
      </>
    );
  }
};

export default Home;
