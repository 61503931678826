import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';


import './About.css';

const About: React.FC = () => {



  // const registerUser = (data:any) => {
  //   console.log('creating a new user account with: ', data);
  // }
  // const convertHere= () =>{
  //   // document.getElementById('convert').innerHTML = "hello";
   
  // }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Help</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Help</IonTitle>
          </IonToolbar>
        </IonHeader>
      </IonContent>

    </IonPage>
  );
};

export default About;
