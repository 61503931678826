import { IonAvatar, IonContent, IonHeader, IonItem, IonList, IonPage, IonTitle, IonToolbar } from '@ionic/react';
// import {realDb} from '../utils/firebase-config';
// import { getDatabase, ref, onValue, set, update} from "firebase/database";
// import { useEffect } from 'react';

// const starCountRef = ref(realDb, 'powerMeter/');
// onValue(starCountRef, (snapshot) => {
//   const data = snapshot.val();
//   console.log(data);
//   // updateStarCount(postElement, data);
// });

import './Settings.css';




const Chat: React.FC = () => {
// console.log(mygetToken());
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Chat</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Chat</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          <IonItem>
            <IonAvatar item-start>
              <img alt="" src="./assets/images/epec1.jpg"/>
            </IonAvatar>
            <p>You made a purchase ...</p>
          </IonItem>
        </IonList>
      </IonContent>

    </IonPage>
  );
};

export default Chat;


// function showToast(arg0: string) {
//   throw new Error('Function not implemented.');
// }

// function initPushNotifications() {
//   throw new Error('Function not implemented.');
// }

