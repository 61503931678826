// import {getMessaging, getToken, Messaging} from "firebase/messaging";
// import { FirebaseApp, initializeApp } from "firebase/app";
import { initializeApp } from "firebase/app";

// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
// import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import 'firebase/compat/firestore';

// const firebaseConfig = {
//   apiKey: "AIzaSyCYl2i1dmJtdhq3jJAaQvk3XAav-x2_e04",
//   authDomain: "smartmetering-d8a8b.firebaseapp.com",
//   projectId: "smartmetering-d8a8b",
//   databaseURL: "https://smartmetering-d8a8b-default-rtdb.firebaseio.com",
//   storageBucket: "smartmetering-d8a8b.appspot.com",
//   messagingSenderId: "835480653063",
//   appId: "1:835480653063:web:1626409c0b09a8176549a2",
//   measurementId: "G-CM1Q8G9KQZ"
// };
const firebaseConfig = {
  apiKey: "AIzaSyCBQJdjnIWgkTGGdjN1w4IolIr-yeV_lUE",
  authDomain: "smartmeter-6604a.firebaseapp.com",
  databaseURL: "https://smartmeter-6604a-default-rtdb.firebaseio.com",
  projectId: "smartmeter-6604a",
  storageBucket: "smartmeter-6604a.appspot.com",
  messagingSenderId: "1039508029195",
  appId: "1:1039508029195:web:0e56430f571ebc3466ac0e",
  measurementId: "G-6F6XR28GY7"
  };
  



const app = initializeApp(firebaseConfig);
// const messaging = firebase.app().messaging();
// const analytics = getAnalytics(smart);
export const db = getFirestore(app);
export const realDb = getDatabase(app);

// const { REACT_APP_VAPID_KEY } = "BITeeI8nPicURME85B_EYVvsDMalqk2omf70GsH_YULe9WMH8mxAHhczgZWXXORNrOzn8Anj3R9yU9T1FnyQBGE"
// const publicKey = "BITeeI8nPicURME85B_EYVvsDMalqk2omf70GsH_YULe9WMH8mxAHhczgZWXXORNrOzn8Anj3R9yU9T1FnyQBGE";
// export const mygetToken:any = async (setTokenFound:any) => {
//   let currentToken = '';
//   try {
//     currentToken = await messaging.getToken({vapidKey: publicKey});
//     if (currentToken) {
//       setTokenFound(true);
//     } else {
//       setTokenFound(false);
//     }
//   } catch (error) {
//     console.log('An error occurred while retrieving token.', error);
//   }
//   return currentToken;
// };

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload) => {
//       resolve(payload);
//     });
//   });

// "rules": {
//   ".read": "now < 1671513478",  // 20 December 2022
//   ".write": "now < 1671513478",  // 20 December 2022
// }
