import { useCallback, useEffect, useState } from 'react';
// import { useHistory } from 'react-router';

import { 
  IonAlert, IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonPage, 
  IonTitle, IonToggle, IonToolbar, 
  // useIonToast
} from '@ionic/react';
import { chevronBack, helpCircle, home, informationCircle, moon } from 'ionicons/icons';
import { wallet, notifications, person, logOut as myLog } from 'ionicons/icons';

import './Settings.css';

interface AlertStateInterface {
  header: string;
  subHeader: string;
  message: string;
  buttons: any;
  showAlert: boolean;
}

export const getPermissions = async (name: any) => {
  return navigator.permissions.query({name: name}).then((result)=>{
    return result;
  });
}

export const urlB64ToUint8Array = (base64String: any)=>{
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  };
  return outputArray;
}


// const Settings: React.FC = ({pushState: boolean}) => {
const Settings: React.FC = () => {
  // const history = useHistory();

  // const [pushCheck, setPushCheck] = useState(pushState);
  const [pushCheck, setPushCheck] = useState(false);
  const [justLoaded, setJustLoaded] = useState(true);

  const [showLoadingState, setShowLoading] = useState({showLoadingMessage:'c...', showLoading: false});
  const [showAlertState, setShowAlertState] = useState<AlertStateInterface>({header: "", subHeader: "", message: "", buttons: [], showAlert: false});
  // const [present, dismiss] = useIonToast();


  const updatePushSubscriptionOnServer = (subscription: any)=>{
    let endpoint; let key; let token; 
    var uint8Array_p256dh:any; var uint8Array_auth:any;
    if (subscription !== null) {
      endpoint = subscription.endpoint; 
      key = subscription.getKey('p256dh'); 
      token = subscription.getKey('auth');

      uint8Array_p256dh = new Uint8Array(subscription.getKey('p256dh'));
      uint8Array_auth = new Uint8Array(subscription.getKey('auth'));
    };

    return fetch("includes/savePushSubscription.php", {
      method: "POST", headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        endpoint: endpoint ? subscription.endpoint : null,
        key: key ? btoa(String.fromCharCode.apply(null, uint8Array_p256dh)) : null,
        token: token ? btoa(String.fromCharCode.apply(null, uint8Array_auth)) : null
      })
    }).then(result=> result.json())
    .catch((err:any)=>{
      console.log(err);
      console.log(err.message);
    });
  }

  const isPushSubscribed = useCallback(async ()=>{
    const serviceWorker = await navigator.serviceWorker.ready;

		if (serviceWorker != null) {
			return serviceWorker.pushManager.getSubscription().then(function(subscription) {				
				return subscription;
			}).then(res=>{
        // console.log(res)
				return {subscribed: !(res === null), subscription: res};
			}).catch(err=>{
				console.log(err);
				return {subscribed: false};
			});
		} else {
			return {subscribed: false, swRegistration: serviceWorker};
		}
	}, []);
  const getPushNotifications = useCallback(async (name:any, appServerPublicKey: any, callback: any, trackCallback: any) => {
    try {
      if ('serviceWorker' in navigator || 'PushManager' in window ) { 
        setShowLoading({...showLoadingState, showLoadingMessage: "Activating push notifications...", showLoading: true});

        // const applicationServerKey = urlB64ToUint8Array(appServerPublicKey);
        // console.log(appServerPublicKey, applicationServerKey);

        const serviceWorker = await navigator.serviceWorker.ready;
        // subscribe and return the subscription
        setShowLoading({...showLoadingState, showLoadingMessage: "Getting device notification details...", showLoading: true});
        return await serviceWorker.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: appServerPublicKey
        }).then(function(subscription: any) { 
          // console.log(subscription);
          setPushCheck(true);
          setShowLoading({...showLoadingState, showLoadingMessage: "Saving notification registration....", showLoading: true});
          return updatePushSubscriptionOnServer(subscription).then(res=>{
            // console.log(res)
            setShowLoading({...showLoadingState, showLoading: false});
            return {state: 'prompt', subscribed: true};
          });
          // present({
          //   duration: 3000,
          //   buttons: [{ text: 'hide', handler: () => dismiss() }],
          //   message: "Push notification NOT acticated",
          //   // onDidDismiss: () => console.log('dismissed'),
          //   // onWillDismiss: () => console.log('will dismiss'),
          // });        
        }).catch((err: any)=>{
          console.log(err);
          setShowLoading({...showLoadingState, showLoading: false});
        });
      } else { 
        callback(false);
        return {state: null, message: 'Please update your browser, \nThis Location service doesn\'t work on this device.'};
      };
    } catch (error: any) {
      // console.log(error.message);
      callback(false);
      return {success: null, message: error.message};
    }
  // }, [showLoadingState, dismiss, present]);
  }, [showLoadingState]);
  const accessCallback = useCallback((access)=>{
    setPushCheck(true);
  }, []);
  const prePushRequest = useCallback(()=>{
    var buttonActions = [
      {
          text: 'Give it a try',
          handler: () => {
            var appServerPublicKey:any = "BFcrXzGXv3UrzWTvrbYgs27tCy_y5OO9FTcvh6ND-zug_jFgOjXcPvEdG_FDO0ZLXeT0nJpIQhKi7PigTtrVRc0";            
            getPushNotifications('notifications', appServerPublicKey, accessCallback, null);
          }
      },
      {
          text: 'Not now',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {

          }
      },
      {
          text: 'Allow while using App',
          handler: () => {
            var appServerPublicKey:any = "BFcrXzGXv3UrzWTvrbYgs27tCy_y5OO9FTcvh6ND-zug_jFgOjXcPvEdG_FDO0ZLXeT0nJpIQhKi7PigTtrVRc0";
            getPushNotifications('notifications', appServerPublicKey, accessCallback, null);
          }
      }
    ];
    var alertStateVars = {
      header: "For best experience",
      subHeader: "Allow Push Notifications", 
      message: "Your phone will ask for Push Notification permission, please select 'Allow' to let our services work best.",
      buttons: buttonActions};
    setShowAlertState({...alertStateVars, showAlert: true});
  }, [accessCallback, getPushNotifications]);

  const updatePushPermission  = (checked: any)=>{
    // console.log(checked)
    if (checked) {
      // console.log("There")
      getPermissions('notifications').then((res:any)=>{
        // console.log(res)
        if (res.state === 'prompt') {
          prePushRequest();
        } else if (res.state === 'granted') {
          var appServerPublicKey:any = "BFcrXzGXv3UrzWTvrbYgs27tCy_y5OO9FTcvh6ND-zug_jFgOjXcPvEdG_FDO0ZLXeT0nJpIQhKi7PigTtrVRc0";            
          getPushNotifications('notifications', appServerPublicKey, accessCallback, null);
        }
      });
    } else {
      // console.log("here.")
      isPushSubscribed().then((subscriptionRes: any)=>{
        // console.log(subscriptionRes);
        if (subscriptionRes) { 
          if (subscriptionRes.subscription) {
            subscriptionRes.subscription.unsubscribe();
          }
        };
        setPushCheck(false);
        setShowLoading({...showLoadingState, showLoadingMessage: "Saving notification state....", showLoading: true});
        updatePushSubscriptionOnServer(null).then(res=>{
          // console.log(res);
          setShowLoading({...showLoadingState, showLoading: false});
          return {state: 'prompt', subscribed: true};
        });
      })
    }
  }

  useEffect(()=>{
    if (justLoaded) {
      isPushSubscribed().then((res: any)=>{
        setPushCheck(res.subscribed)
      });
      setJustLoaded(false);
    }
  }, [isPushSubscribed, justLoaded]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="home" text="Back" icon={chevronBack} />
          </IonButtons>
          <IonTitle>Menu</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Menu</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div>
          <br/>
          <br/>
          <br/>
          <IonList>
            <IonItem  >
              <IonIcon icon={person} />
              <IonLabel className="myLabels">Profile</IonLabel>
            </IonItem>
            <IonItem >
              <IonIcon icon={wallet} />
              <IonLabel className="myLabels">Payment cards</IonLabel>
            </IonItem>
            <IonItem>
              <IonIcon icon={notifications} />
              <IonLabel className="myLabels">Push notifications</IonLabel>
              <IonToggle color="primary" checked={pushCheck} onIonChange={(e)=>updatePushPermission(e.detail.checked)}/>
            </IonItem>
            <IonItem>
              <IonIcon icon={moon} />
              <IonLabel className="myLabels">Dark mode</IonLabel>
              <IonToggle color="primary" />
            </IonItem>
            
            <IonItem >
              <IonIcon icon={helpCircle} />
              <IonLabel className="myLabels">Help</IonLabel>
            </IonItem>
            <IonItem >
              <IonIcon icon={informationCircle} />
              <IonLabel className="myLabels">Terms and Conditions</IonLabel>
            </IonItem>
            <IonItem >
              <IonIcon icon={home} />
              <IonLabel className="myLabels">About us</IonLabel>
            </IonItem>
            <IonItem>
              <IonIcon icon={myLog} />
              <IonLabel className="myLabels">LogOut</IonLabel>
            </IonItem>
          </IonList>
        </div>
      </IonContent>

      <IonLoading
          isOpen={showLoadingState.showLoading}
          onDidDismiss={() => setShowLoading({...showLoadingState, showLoading: false})}
          message={showLoadingState.showLoadingMessage}
          />
      <IonAlert
        isOpen={showAlertState.showAlert}
        onDidDismiss={() => setShowAlertState({...showAlertState, showAlert: false})}
        header={showAlertState.header}
        subHeader={showAlertState.subHeader}
        message={showAlertState.message}
        buttons={showAlertState.buttons}
      />
    </IonPage>
  );
};

export default Settings;