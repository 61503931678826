import { IonAlert, IonButton, IonButtons, IonCard, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonModal, IonPage, IonPopover, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import { cart, cash, sync, closeCircle, cutOutline, chevronForwardCircle, share, shareOutline, shareSocialSharp, cutSharp, cashSharp, add } from 'ionicons/icons';
import { useRef, useState, MouseEvent, useEffect} from 'react';
import {db, realDb} from '../utils/firebase-config';
// import {collection, getDocs, addDoc, doc, updateDoc} from 'firebase/firestore';
import {collection, getDocs, addDoc, updateDoc, doc} from 'firebase/firestore';
// import { getDatabase, ref, onValue, set, update} from "firebase/database";
import { ref, update} from "firebase/database";

import axios from 'axios';

// import { Controller, useForm } from 'react-hook-form';

import voucher_codes from 'voucher-code-generator';
import './Voucher.css';
// import { DiffieHellman } from 'crypto';
// import { Console } from 'console';

const Voucher: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [show2Modal, setShow2Modal] = useState(false);
  const [show3Modal, setShow3Modal] = useState(false);
  const [show4Modal, setShow4Modal] = useState(false);
  const [show5Modal, setShow5Modal] = useState(false);
  const [vanShow, setVanShow] = useState('none');
  const [buyElect, setbuyElect] = useState('none');
  const [myVouchers, setMyVouchers] = useState<any>("Loading vouchers...");
  // const [storedVouchers, setStoredVouchers] = useState<any>([]);
  

  const [showAlertState, setShowAlert] = useState<{header: string, subHeader: string, message: string, buttons: any[], showAlert: boolean}>({header: "", subHeader: "", message: "", buttons: [], showAlert: false});
  const [popoverState, setShowPopover] = useState<{showPopover: boolean, popOverList: any[], event: any}>({ showPopover: false, popOverList: [], event: undefined });
  const [showLoadingState, setShowLoading] = useState({showLoadingMessage:'c...', showLoading: false});




  // const [selected, setSelected] = useState<string>('Home');
  const [finalAndSplity, setFinalAndSplity]= useState<{docID: any, splitty: number, final: any, total: any, fee: any, initial: any, voucher: any, newVoucher: any, voucherStatus:any}>({docID:"", splitty: 0, final: 0, total: 0, fee: 0, initial: 0, voucher: 0, newVoucher: 0, voucherStatus:0});
  const [inputVoucher, setInputVoucher]= useState({docID: "", value: 0, voucher: 0, fee: 0.02, finalAmount:0});
  // const [getfirebasevoucher, setgetFirebasevoucher]= useState({firevalue: 0, firevoucher: 0, firefee: 0.02, firefinalAmount:0});

  const testRef = useRef(null);
  const amountInput = useRef(null);
  const voucherInput = useRef(null); 
  const splitmoney = useRef(null);
  const vanishing = useRef(null);

   
  const collectionRef = collection(db, "vouchers")
 
  function writeUserData(mylastToken:any , mylasTokenValue:any, shareObj: any) {
    update(ref(realDb, 'powerMeter/'), {
      lastToken: mylastToken,
      lasTokenValue: mylasTokenValue
    })
    .then(() => {
      updateVoucher("update", shareObj);
      console.log("sent")
    })
    .catch((error) => {
      console.log(error)
    });
  } 

  
  const conversion =() => {
    let myvoucher:string = voucher_codes.generate({prefix: "Mp24-",postfix: "-2022"})[0];
    return myvoucher;
  }
  const tokenConversion =() => {
    let tokenconvert:string = voucher_codes.generate({length:16,count:0, charset:"0123456789"})[0];
    return tokenconvert;
  }

  
  const updateVoucher = async (action: string, newObj: any)=>{
    //Here we update the last voucher to have a new value after the split.
    console.log(newObj);

    if (newObj.splitty !== "") {
      const collectionVoucherRef = doc(db, "vouchers", newObj.docID);

      await updateDoc(collectionVoucherRef, {
        finalAmount: parseInt(newObj.final),
        status: 1,
      }).then(() => {
        console.log("updated");
        if (action === "split") {
          
          if (newObj.final !== newObj.splitty) {
            let vGen:any = conversion();
            logVoucherPurchase(newObj.splitty, vGen);
            
          console.log("Splitted successfully");
          } else {
            console.log("Direct sharing successful.");

          }
        } else {
          
        };
        setVanShow('none');
        setShow5Modal(false);
      }).catch((error: any) => {
        console.log(error)
      });
    } else {
      alert("The split amount is dead.");
    }
  }

  const doSharing = (action:string, shareObj: any)=>{
    console.log(shareObj)

    if (action === "buy") {
      setShowLoading({showLoadingMessage:'Verifying purchase...', showLoading: true});

      setTimeout(() => {
        setShowLoading({showLoadingMessage:'Loading my vouchers', showLoading: false});
        setTimeout(() => {
          var buttons:any[] = [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                  console.log('Confirm Cancel');
                  // setMyVouchers([{number: "cbchg", amount: "200"}, {number: "1561ebwhje", amount: "300"}]);
              }
            },
            {
                text: 'Done',
                handler: () => {
                  // console.log(shareObj)
                  // doSharing("buy", shareObj);
                }
            }
          ];
          console.log(shareObj);          
          setShowAlert({header: "Successful", subHeader: "Electricity purchased", message: "Electricity purchased", buttons: buttons, showAlert: true});
          alert("Shared Successfully");
          //Add the voucher updater function here.
          updateVoucher('update', shareObj);
        }, 500);
      }, 1000);
    } else if (action === "share"){

      if (navigator.share) {
        navigator.share({
          title: 'MP24 Voucher - Shared',
          url: 'https://mp24.co.za?voucher='+shareObj.newVoucher,
          text: 'Voucher of R'+shareObj.splitty+' \nVoucher: '+shareObj.newVoucher,
        }).then(async() => {
          console.log('Thanks for sharing!');
         await addDoc(collectionRef, {finalAmount: shareObj.splitty, voucherNo:shareObj.newVoucher, status:0});

          //Add the voucher updater function here.
          updateVoucher('split', shareObj);
        })
        .catch(console.error);
      } else {
        // fallback
        alert("Can't show share options.")
      }
    }
  }

  const convertVoucher = () => {   
    const verifyVoucher = (voucherInput: any)=>{
      if (voucherInput.current !== null) {
        setShowLoading({showLoadingMessage:'Verifying voucher...', showLoading: true})
        let myVoucherInput:any = voucherInput.current;
        var isMatch = false;
        var myFinalAmount:any;
        // var myFee:any;
        if (typeof myVouchers !== "string") {
          for (let index = 0; index < myVouchers.length; index++) {
            let element:any = myVouchers[index];
            console.log(element)
            if (element.voucherNo === myVoucherInput.value) {
              myFinalAmount = element.finalAmount;
              // myFee = element.finalAmount;
              // console.log(myFinalAmount);
              isMatch = true;
              break;
            }
          };
        }
        var buttons:any[] = [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
                console.log('Confirm Cancel');
            }
          },
          {
              text: 'Retry',
              handler: () => {
                verifyVoucher(voucherInput);
              }
          }
        ];
        if (!isMatch) {
            setTimeout(() => {
              setShowAlert({header: "Voucher is invalid", subHeader: "", message: "voucher is invalid", buttons: buttons, showAlert: true});
            }, 600);
        } else {
          buttons[1] = {
            text: 'Continue',
              handler: () => {
                // verifyVoucher(voucherInput);
                setInputVoucher({...inputVoucher, voucher: myVoucherInput.value, finalAmount: myFinalAmount, fee: 0.02 });
                console.log(inputVoucher)
              }
          }
          setTimeout(() => {
            setShowAlert({header: "Voucher verified", subHeader: "", message: "voucher verified", buttons: buttons, showAlert: true});
          }, 600);
      setShow4Modal(true);

        }
        setShowLoading({showLoadingMessage:'Verifying voucher...', showLoading: false})
      }
    }
    verifyVoucher(voucherInput);

  };
  
  const handleClick = async (event: MouseEvent) => {
    event.preventDefault();
    
    if (amountInput.current !=null) {
      let myMoney:any = amountInput.current;
      // let vGen:any = conversion();
      if (myMoney.value.length === 0) {
        var buttons:any[] = [
          {
            text: 'Back',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel');
            }
          },
          {
            text: 'Double check',
            handler: () => {
  
            }
          }
        ];
        setShowAlert({header: "No Value", subHeader: "", message: "Please enter amount", buttons: buttons, showAlert: true});
        
      }else{
        // await addDoc(collectionRef, {finalAmount: myMoney.value, voucherNo: vGen, status:0});
        setShow2Modal(true)        
        // console.log(myMoney.value.length);
        // console.log(vGen);
    }
     
    }
  };
  const logVoucherPurchase = async (myMoney: number, vGen: string)=>{
    await addDoc(collectionRef, {finalAmount: myMoney, voucherNo: vGen, status:0});
  }
 const split= () => {
   console.log(inputVoucher)
   if (splitmoney.current !== null) {   
    let vanish:any = vanishing.current;     
    let splitting:any = splitmoney.current;
    let mesplity:any = splitting.value;
    
            // let totalVal:number = initial - mesplity;
    console.log("splitting")
    let totalVal:number = inputVoucher.finalAmount - mesplity;
    if (totalVal >= 0) {
      var newVoucher = conversion();
      // var finalVal = totalVal - ((inputVoucher.fee)*(inputVoucher.value));
      var finalVal = totalVal - ((inputVoucher.fee)*(inputVoucher.finalAmount));
      setFinalAndSplity({...finalAndSplity, docID: inputVoucher.docID, splitty: mesplity, final: finalVal, total: totalVal,
        fee: inputVoucher.fee, initial: 0, voucher: inputVoucher.voucher, newVoucher: newVoucher, voucherStatus: 0});
      // console.log(finalVal);
      if (vanish) {
        vanish.style.display = "block";
      }
    } else {
      var buttons:any[] = [
        {
          text: 'Back',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        },
        {
          text: 'Double check',
          handler: () => {

          }
        }
      ];
      setShowAlert({header: "Not engough money", subHeader: "", message: "Split amount higher than voucher value.", buttons: buttons, showAlert: true});
    }
   } else {
     alert("vnrjek");
   };
 }

  // console.log(tokenConversion());

  // const registerUser = (data:any) => {
  //   console.log('creating a new user account with: ', data);
  // }
  const clickOnVoucher = (e:any, voucher: any)=>{
    // console.log(voucher)
    var popOverList = [];
    popOverList.push({
      key: 3,
      icon: cashSharp, 
      text: 'Buy Electricty',
      lines: 'full',
      detail: false,
      handler: () => {
        var buttons:any[] = [
          {
            text: 'Back',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel');
            }
          },
          {
            text: 'Yes, Continue',
            handler: () => {
              if (true) {
                var shareObj = {...finalAndSplity, docID: voucher.id, splitty: voucher.finalAmount, final: voucher.finalAmount, total: voucher.finalAmount,
                  fee: voucher.fee, initial: voucher.intialAmount, voucher: voucher.voucherNo, newVoucher: voucher.voucherNo, status: voucher.voucherStatus
                };
                doSharing("buy", shareObj);
                  writeUserData(tokenConversion(), voucher.finalAmount/2, shareObj);
                  console.log(voucher.finalAmount);
  
                // var statusUpdate = voucher.status;
                
                // if (statusUpdate !== 0 ) {
                //   console.log("zero")
                //   // writeUserData(tokenConversion(), voucher.finalAmount/2);
                // }else{ 
                //   console.log("one")
  
                //   writeUserData(tokenConversion(), voucher.finalAmount/2);
                // }
              }
            }
          }
        ];
        setShowAlert({header: "Are you sure", subHeader: "Are you sure you want to purchase electricity worth:", message:"R"+ voucher.finalAmount, buttons: buttons, showAlert: true});
      }
    });
    popOverList.push({
      key: 2,
      icon: cutSharp, 
      text: 'Split voucher',
      lines: 'full',
      detail: false,
      handler: () => {
        
          setShow5Modal(true);
          
          setInputVoucher({...voucher, docID: voucher.id, fee: 0.02 })
          // console.log(shareObj)
        }
    });
    popOverList.push({
      key: 1,
      icon: shareSocialSharp, 
      text: 'Share voucher',
      lines: 'full',
      detail: false,
      handler: () => {
        var shareObj = {...finalAndSplity, docID: voucher.id, splitty: voucher.finalAmount, final: voucher.finalAmount, total: voucher.finalAmount,
          fee: voucher.fee, initial: voucher.intialAmount, voucher: voucher.voucherNo, newVoucher: voucher.voucherNo
        };
        doSharing("share", shareObj);
      }
    });
    setShowPopover({ showPopover: true, popOverList: popOverList, event: e });
  }
  const orderWithCard = (e: any)=>{
    e.preventDefault();
    var formData = new FormData(e.target);
    var formDataJSON = Object.fromEntries(formData);
    if (formDataJSON.cardBrand !== "") {
        // var cardData = {
        //     cardBrand: formDataJSON.cardBrand, cardNumber: formDataJSON.cardNumber, 
        //     expiry: (formDataJSON.month)+"/"+(formDataJSON.year), cvv: formDataJSON.cvv
        // };
        submitOrder("payNow", formDataJSON);
    } else {
        var buttonActions = [
            {
                text: 'Ok',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                }
            }
        ];

        var alertStateVars = {header: "Card information", subHeader: "e.g Visa, Mastercard, etc.", message: "Please tell choose card brand.", buttons: buttonActions};
        setShowAlert({...alertStateVars, showAlert: true});
    }
}

  const submitOrder = async (purchaseOpt:any, extra: any)=>{
    let myMoney:any = amountInput.current;
    let vGen:any = conversion();
    var shoppingCart:any = {voucher: vGen, amount: myMoney.value};
    var orderForm = new FormData();
    orderForm.append("shoppingCart", JSON.stringify(shoppingCart));
    
    orderForm.append("cardData", JSON.stringify(extra));
    setShowLoading({showLoadingMessage: "Submitting order...", showLoading: true});
    var url = "includes/payment.php";
    const config = {
        headers: {
            "Content-Type": "multipart/form-data" 
        }
    };
    axios.post(url, orderForm, config).then((result:any)=>{
        return result.data;
    }).then(response=>{
      console.log(response);
      setTimeout(() => {
          setShowLoading({...showLoadingState, showLoading: false});
      }, 900);
      if (response.success) {
        
          logVoucherPurchase(shoppingCart.amount, shoppingCart.voucher);
          var alertStateVarsSuccess = {
              header: response.msg, subHeader: response.msg3, message: response.msg2, 
              buttons: [{
                  text: 'Ok',
                  handler: () => {
                      console.log('Order done.');
                      localStorage.removeItem("shoppingCart");
                      setShow2Modal(false);
                      setTimeout(() => {
                        setShowModal(false);
                      }, 300);
                  }
              }]
          };
          setTimeout(() => {
              setShowAlert({...alertStateVarsSuccess, showAlert: true});
          }, 1001);
      } else {
        var theOpt = "stop";
          var optionsForFails:any = {retry: {value: true, text: "Retry"}, stop: {value: false, text: "Ok"}};
          if (response.data) {
              if (response.data.orderTrace) {
                  theOpt = "retry";
              }
          };
          var buttonActions = [
              {
                  text: 'Back',
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: () => {
                      console.log('Cancellation');
                  }
              },
              {
                  text: optionsForFails[theOpt].text,
                  handler: () => {
                      if (optionsForFails[theOpt].value) {
                          submitOrder(purchaseOpt, extra);
                      };
                  }
              }
          ];
          var alertStateVarsFail = {header: response.msg, subHeader: "", message: response.msg2, buttons: buttonActions};
          setTimeout(() => {
              setShowAlert({...alertStateVarsFail, showAlert: true});
          }, 1001);
      }
  }).catch(err=>{
      setTimeout(() => {
          setShowLoading({...showLoadingState, showLoading: false});
      }, 900);

      var buttonActions = [
        {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
                console.log('Confirm Cancel');
            }
        },
        {
            text: 'Retry',
            handler: () => {
                submitOrder(purchaseOpt, extra);
            }
        }
      ];
        
      var alertStateVars = {header: "Error encountered", subHeader: "", message: err.message, buttons: buttonActions};
      setTimeout(() => {
          setShowAlert({...alertStateVars, showAlert: true});
      }, 1001);
    });
  }

  useEffect(()=>{

    const getVouchers =async ()=> {
      const data = await getDocs(collectionRef)
      var pulledVoucherList = data.docs.map((doc:any) => ({ ...doc.data(), id: doc.id}));
      setMyVouchers(pulledVoucherList);
    }
    getVouchers();
  },[collectionRef])
  
  return (
  
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Voucher Manager</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Voucher Manager</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* Payment Voucher */}
        <IonModal isOpen={showModal} cssClass='my-custom-class1' swipeToClose={true} >
          <IonHeader>
              <IonToolbar>
                  <IonButtons slot="end">
                      <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
                  </IonButtons>
                  <IonTitle>Buy voucher</IonTitle>
              </IonToolbar>
          </IonHeader>
          <IonContent>            
              <IonCard>
                      <IonList>
                          <IonItem >
                              <IonLabel>Enter Amount</IonLabel>
                              <IonInput placeholder="Enter Amount" type='number' ref={amountInput}></IonInput>
                          </IonItem>                    
                      </IonList>                  
                      <IonButton color="secondary" expand='full' onClick={handleClick}   ><IonIcon slot="start" icon={cash}/>Make payment</IonButton>
              </IonCard>
          </IonContent>
           
        </IonModal>
        <IonModal isOpen={show2Modal} cssClass='my-custom-class1' swipeToClose={true} >
        {/* <IonBackdrop tappable={true} onClick={() => setShowModal(false)}/> */}
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton onClick={() => setShow2Modal(false)}>Close</IonButton>
                    </IonButtons>
                    <IonTitle>Add a Card</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
            <IonCard className="checkoutCard">
              <form onSubmit={orderWithCard}>
                  <IonList>
                      <IonItem>
                          <IonLabel position="floating">Card Holder Names</IonLabel>
                          <IonInput name="cardHolder" placeholder="Firstname Lastname" required/>
                      </IonItem>
                      <IonItem>
                          <IonLabel position="floating">Card Brand</IonLabel>
                          <IonSelect 
                          // ref={deliveryOptsRef}
                          id="cardBrand"
                          interface="popover"
                          placeholder="Select one" 
                          name='cardBrand'
                          aria-required
                          >
                              <IonSelectOption value="VISA">VISA</IonSelectOption>
                              <IonSelectOption value="MASTER">MASTER CARD</IonSelectOption>
                          </IonSelect>
                      </IonItem>
                      <IonItem>
                          <IonLabel position="floating">Card Number</IonLabel>
                          <IonInput name="cardNumber" placeholder="0000 0000 0000 0000" required/>
                      </IonItem>
                      
                      <IonItem>
                          <IonLabel position="floating">CVV</IonLabel>
                          <IonInput name="cvv" type='tel' minlength={3} maxlength={3} placeholder="CVV" required/>
                      </IonItem>
                      
                      <IonItem>
                          <IonLabel>
                              <IonLabel position="floating">MM </IonLabel>
                              <IonInput name="month" type='tel' minlength={2} maxlength={2} placeholder="MM" required/>
                          </IonLabel>
                      </IonItem>
                      <IonItem>
                          <IonLabel >
                              <IonLabel position="floating">YY </IonLabel>
                              <IonInput name="year" type='tel' minlength={2} maxlength={2} placeholder="YY" required/>
                          </IonLabel>
                      </IonItem>
                  </IonList>
                  <div className='payBtnHolder'>
                      <IonButton className="payBtn" slot='end' type='submit'>Checkout<IonIcon icon={chevronForwardCircle}/></IonButton>
                  </div>
              </form>
          </IonCard></IonContent>
        </IonModal>

        {/*Converting Voucher */}
        <IonModal isOpen={show3Modal} cssClass='my-custom-class1' swipeToClose={true}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton onClick={() => setShow3Modal(false)}>Close</IonButton>
                    </IonButtons>
                    <IonTitle>Retrieve Management</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>              
            <IonCard>
                    <IonList>
                        <IonItem >
                            <IonLabel>Enter Voucher</IonLabel>
                            <IonInput placeholder="Enter Voucher"  ref={voucherInput} required ></IonInput>
                        </IonItem>                    
                    </IonList>                  
                    <IonButton expand='full' onClick={() => convertVoucher()} ref={testRef}  ><IonIcon slot="start" icon={add}/>Retrieve Voucher</IonButton>
            </IonCard>
          </IonContent>
          
            
          <IonModal isOpen={show4Modal} cssClass='my-custom-class1' swipeToClose={true} >
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton onClick={() => setShow4Modal(false)}>Close</IonButton>
                    </IonButtons>
                    <IonTitle>Voucher Management</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                        <IonHeader collapse="condense">
                            <IonToolbar>
                                <IonTitle size="large">Voucher </IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <IonCard>
                                <IonList>
                                  
                                    <IonItem >
                                        <IonLabel>Amount</IonLabel>
                                        <IonLabel slot="end">R{inputVoucher.finalAmount}</IonLabel>
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel>Voucher No.</IonLabel>
                                        <IonLabel slot="end">{inputVoucher.voucher}</IonLabel>
                                    </IonItem>
                                </IonList>
                                <IonButton  onClick={() => setShow5Modal(true)} ><IonIcon slot="start" icon={cutOutline} />Split Voucher</IonButton>
                                OR
                                <IonButton color="secondary" ><IonIcon slot="start" icon={cash}/>Buy Electricity</IonButton>
                        </IonCard>
                    </IonContent>           
          </IonModal>         
        </IonModal>
        <IonModal isOpen={show5Modal} cssClass='my-custom-class1' swipeToClose={true} >
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton onClick={() => setShow5Modal(false)}>Close</IonButton>
                    </IonButtons>
                    <IonTitle>Split Voucher</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Split </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonCard>
                        <IonList>
                            <IonItem>
                                <IonLabel>Amount</IonLabel>
                                <IonInput type='number' placeholder="Enter Amount in R" id='splitVoucherInput' ref={splitmoney} slot="end"></IonInput>
                            </IonItem>
                        </IonList>                                              
                        <IonButton expand='block' onClick={()=>{
                          if (splitmoney.current !== null) {
                            let splitting:any = splitmoney.current;
                            console.log(splitting.value)
                            if ((splitting.value !== 0)&&(splitting.value !== "")) {
                              split()
                            }
                          }
                        }}><IonIcon slot="start" icon={cutOutline} />Split Voucher</IonButton>                                     
                </IonCard>
                <IonCard className="vanish" ref={vanishing}>
                        <IonList>
                          <IonItem>
                              <IonLabel>Original Voucher</IonLabel>
                              <IonLabel slot="end">R{inputVoucher.finalAmount}</IonLabel>
                          </IonItem>
                          <IonItem>
                              <IonLabel>Split Amount</IonLabel>
                              <IonLabel slot="end">R{finalAndSplity.splitty}</IonLabel>
                          </IonItem>
                          <IonItem>
                              <IonLabel>Service fee</IonLabel>
                              <IonLabel slot="end">R{((inputVoucher.fee)*(inputVoucher.finalAmount))}</IonLabel>
                          </IonItem>  
                          <IonItem>
                              <IonLabel>Remaining </IonLabel>
                              <IonLabel slot="end">{
                                (finalAndSplity.final >= 0)?(
                                  "R"+(finalAndSplity.final)
                                ):(
                                  "-R"+Math.abs(finalAndSplity.final)
                                )
                              }</IonLabel>
                          </IonItem>
                        </IonList>                                            
                        <IonButton onClick={() => {setVanShow('block');   setbuyElect('none')}}><IonIcon slot="start" icon={shareOutline} />Share Voucher</IonButton> OR
                        <IonButton color="secondary" onClick={() => {setbuyElect('block'); setVanShow('none')}}><IonIcon slot="start" icon={cash} />Buy Electricity</IonButton>
                        
                </IonCard>
                <IonCard  style={{display : vanShow}}>
                  <IonItem>
                    <IonLabel>Share </IonLabel>
                    <IonInput placeholder="Cell No" slot="end"></IonInput>
                  </IonItem>
                  <IonItem>                                    
                      <IonLabel>House</IonLabel>
                      <IonSelect 
                      // ref={deliveryOptsRef}
                      id="deliverySelect"
                      interface="popover"
                      placeholder="Select one"
                      >
                          <IonSelectOption >House 1</IonSelectOption>
                          <IonSelectOption >House 2</IonSelectOption>
                      </IonSelect>
                  </IonItem>
                  <IonButton onClick={()=>{doSharing("share", finalAndSplity)}} ><IonIcon slot="start" icon={share} />Share</IonButton>
                  <IonButton onClick={() => setVanShow('none')} color="danger"><IonIcon slot="start" icon={closeCircle} />close</IonButton>


                </IonCard>
                <IonCard  style={{display : buyElect}}>
                  <IonItem>
                    <IonLabel>Enter Meter number </IonLabel>
                    <IonInput placeholder="Cell No" slot="end"></IonInput>
                  </IonItem>
                  <IonItem>                                    
                      <IonLabel>Frequent Meter</IonLabel>
                      <IonSelect 
                      // ref={deliveryOptsRef}
                      id="deliverySelect"
                      interface="popover"
                      placeholder="Select one"
                      >
                          <IonSelectOption >Meter 1</IonSelectOption>
                          <IonSelectOption >Meter 2</IonSelectOption>
                      </IonSelect>
                  </IonItem>
                  <IonButton onClick={()=>{doSharing("buy", finalAndSplity)}}><IonIcon slot="start" icon={cash} />Buy</IonButton>
                  <IonButton onClick={() => setbuyElect('none')} color="danger"><IonIcon slot="start" icon={closeCircle} />close</IonButton>


                </IonCard>
            </IonContent>             
          </IonModal> 
      
        <IonCard className="myBox">
            
            <IonGrid className="myGrid">
              <IonRow className="myRow ">
                <IonCol className="myCol">                  
                  <div className="myMiniBut" onClick={() => setShowModal(true)}>
                    <IonIcon icon={cart} className="myIcon" />
                    <p>Buy Voucher</p>
                  </div>
                </IonCol>
                <IonCol className="myCol ion-align-items-stretch">
                  <div className="myMiniBut" onClick={() => setShow3Modal(true)}>
                    <IonIcon icon={sync} className="myIcon" />
                    <p>Get Voucher</p>
                  </div>
                </IonCol>
              </IonRow>
              <IonList>
                <h2>My Vouchers</h2>                
                {
                  (typeof myVouchers !== "string")?(
                    (myVouchers.length > 0)?(
                      myVouchers.map((voucher: any)=>{
                        // console.log(voucher)
                        return <IonItem key={voucher.id} lines="full" className="statusVanish" onClick={(e)=>{clickOnVoucher(e, voucher)}}>
                          <IonLabel>{voucher.voucherNo}</IonLabel>
                          <IonLabel slot="end">{voucher.finalAmount}</IonLabel>
                        </IonItem>;
                      })
                    ):("You don't have any vouchers yet.")
                  ):(myVouchers)
                }
              </IonList>              
            </IonGrid>
        </IonCard>


        <IonLoading
          isOpen={showLoadingState.showLoading}
          onDidDismiss={() => setShowLoading({...showLoadingState, showLoading: false})}
          message={showLoadingState.showLoadingMessage}
          />
        <IonAlert
            isOpen={showAlertState.showAlert}
            onDidDismiss={() => setShowAlert({...showAlertState, showAlert: false})}
            header={showAlertState.header}
            subHeader={showAlertState.subHeader}
            message={showAlertState.message}
            buttons={showAlertState.buttons}
        />
        <IonPopover
            cssClass='my-custom-class'
            event={popoverState.event}
            isOpen={popoverState.showPopover}
            onDidDismiss={(e) => {setFinalAndSplity({...finalAndSplity, docID: ""}); setShowPopover({ showPopover: false, popOverList: [], event: e })}}
        >
            <IonList>
                <IonListHeader className="popOverHead">Item Actions</IonListHeader>
                <br/>
                {
                    ((popoverState.popOverList).length > 0)?((popoverState.popOverList).map(elem=>{
                        var showIcon:any;
                        if (elem.icon) {
                            showIcon = <IonIcon icon={elem.icon} />;
                        }
                        return <IonItem key={elem.key}
                            lines={elem.lines} detail={elem.detail} onClick={elem.handler} className={elem.className} button>
                            {showIcon}
                            <IonLabel>{elem.text}</IonLabel>
                            </IonItem>;
                    })):""
                }
            </IonList>
        </IonPopover>
      </IonContent>
    </IonPage>
  );
};

export default Voucher;


